import { useEffect } from "react"

const HomeRedirect = () => {
  useEffect(() => {
    window.location.replace("https://www.itau.com.br/consorcios")
  }, [])

  return <></>
}

export default HomeRedirect
